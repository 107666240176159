define("discourse/plugins/discourse-topic-custom-fields/discourse/raw-templates/connectors/topic-list-after-title/topic-custom-field-topic-list-after-title", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <span>|</span>\n  <a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.lastUnreadUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 11
          },
          "end": {
            "line": 3,
            "column": 42
          }
        }
      })) + "' class='" + alias2(lookupProperty(helpers, "get").call(alias1, "context.customFieldName", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 51
          },
          "end": {
            "line": 3,
            "column": 78
          }
        }
      })) + "' data-topic-id='" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.id", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 95
          },
          "end": {
            "line": 3,
            "column": 115
          }
        }
      })) + "'>\n    <span>" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(alias1, "topic_custom_field.label", {
        "name": "i18n",
        "hash": {
          "value": "context.customFieldValue",
          "field": "context.customFieldName"
        },
        "hashTypes": {
          "value": "PathExpression",
          "field": "PathExpression"
        },
        "hashContexts": {
          "value": depth0,
          "field": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 10
          },
          "end": {
            "line": 4,
            "column": 106
          }
        }
      })) + "</span>\n  </a>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "context.showCustomField", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 7
          }
        }
      })) != null ? stack1 : "";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-after-title/topic-custom-field-topic-list-after-title", template, {
    core: false,
    pluginName: "discourse-topic-custom-fields",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});